<template>
    <v-container>
        <v-row justify="center" class="pt-10">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <div class="pa-5 text-center" v-if="loading">
                    <p class="text-h4 font-weight-light">Loading...</p>
                </div>
                <div class="pa-5 text-center" v-if="errorUnknownInteraction">
                    <p class="text-h5 font-weight-light red--text">We cannot process this request.</p>
                </div>
                <div class="pa-5 text-center" v-if="errorUnauthorizedInteraction">
                    <v-row justify="center">
                        <p class="text-h5 font-weight-light red--text">Unauthorized</p>
                    </v-row>
                    <v-row justify="center">
                        <p class="text-h5 font-weight-light">Did you start in another browser? Try opening the link in that browser, or start over in this one.</p>
                    </v-row>
                    <v-row justify="center">
                        <p class="text-body-1 font-weight-light text-center"><router-link to="/">Start over</router-link></p>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
// import loginshield from '@/client';

export default {
    components: {
    },
    data() {
        return {
            interactionId: null,
            isViewReady: false,
            loading: true,
            errorUnknownInteraction: false,
            errorUnauthorizedInteraction: false,
        };
    },
    methods: {
        init() {
            console.log('interaction.vue: init; isAuthenticated: %o', this.isAuthenticated);
            if (this.interactionId) {
                this.fetchInteractionInfo();
            }
        },
        createAccount({ state }) {
            const { isVerified, isExisting } = state;
            if (isExisting) {
                this.$router.replace({ path: '/create-account/conflict', query: { i: this.interactionId } });
                return;
            }
            if (isVerified) {
                this.$router.replace({ path: '/create-account/login', query: { i: this.interactionId } });
                return;
            }
            this.errorUnknownInteraction = true;
        },
        async fetchInteractionInfo(prevInteraction = {}) {
            const { type, next, state } = await this.$store.dispatch('loadInteraction', this.interactionId);
            this.loading = false;
            console.log(`interaction.vue: fetchInteractionInfo: interaction type: ${type} next: ${next}`);
            switch (type) {
            case 'create_account':
                this.createAccount({ type, next, state }, prevInteraction);
                break;
            default:
                console.error('interaction.vue: unknown interaction type: %s', type);
                this.errorUnknownInteraction = true;
                break;
            }
        },
    },
    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
    },
    watch: {
        isReady(value, oldValue) {
            console.log('interaction.vue: isReady changed from %o to %o', oldValue, value);
            // only call init again if ready changed from false to true after mounted()
            if (value && !oldValue) {
                this.init();
            }
        },
    },
    created() {
        if (this.$route.query.error) {
            console.log(`interaction.vue: created, error: ${this.$route.query.error}`);
            this.loading = false;
            switch (this.$route.query.error) {
            case 'unauthorized':
                this.errorUnauthorizedInteraction = true;
                break;
            default:
                this.errorUnknownInteraction = true;
            }
        } else {
            this.interactionId = this.$route.query.i;
            console.log(`interaction.vue: created, interactionId: ${this.interactionId}`);
        }
    },
    mounted() {
        console.log('interaction.vue: mounted');
        if (this.isReady) {
            this.init();
        }
    },
};
</script>
